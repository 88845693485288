import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

/**
 * `InputRowComponent` is a reusable Angular component that can be used to create a input row with a label and any input.
 *
 * @example Set the label and send any input to the component as a child:
 * ```html
 * <gw-cm-input-row
 *   label="Payment method"
 * >
 *  <gw-text-input formControlName="companyName"></gw-text-input>
 * </gw-cm-input-row>
 * ```
 *
 * @example Can send any kind of input to the component as a child:
 * ```html
 * <gw-cm-input-row
 *   label="Initial subscription date"
 * >
 *  <gw-dropdown formControlName="plan" [options]="['Plan 1', 'Plan 2']"></gw-dropdown>
 * </gw-cm-input-row>
 * ```
 */
@Component({
  selector: 'gw-cm-input-row',
  templateUrl: './input-row.component.html',
  standalone: true,
  imports: [NgClass],
})
export class InputRowComponent {
  @Input({ required: true })
  public label!: string;
}
