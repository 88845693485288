import { CommonModule } from '@angular/common';
import { Component, computed, input, output, ViewEncapsulation } from '@angular/core';

/**
 * A standalone Angular component that renders a banner.
 * Allows users to select color and size. Can also set the banner to be dismissable.
 * The component can be styled with custom classes.
 * It emits an event when it is dismissed.
 *
 * @inputs
 *  - `isDismissable`: Boolean indicating whether to show the dismiss button.
 *  - `color`: A string specifying which color banner to render. Can be 'yellow' or 'blue'.
 *  - `size`: A string specifying the size of the banner. Can be 'small' or 'regular'.
 * @outputs
 *  - `dismissEvent`: Emits an event indicating dismiss has been clicked.
 *
 * @example
 * <gw-banner [isDismissable]="true" color="blue" size="regular">
 *    Lorem ipsum the banner content goes inside this container.
 * </gw-banner>
 */
@Component({
  selector: 'gw-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  standalone: true,
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.None,
})
export class BannerComponent {
  /*
   * An event emitted when the banner is dismissed.
   */
  public dismissEvent = output<string>();
  /*
   * A boolean indicating whether to show the dismiss button.
   */
  public isDismissable = input<boolean>(false);
  /*
   * A string specifying which colour scheme to use for the banner.
   */
  public color = input<'blue' | 'yellow'>('blue');
  /*
   * A string specifying which size banner to render.
   */
  public size = input<'small' | 'regular'>('regular');

  emitDismissEvent() {
    this.dismissEvent.emit('dismissed');
  }

  public containerClasses = computed(() => {
    return [`gw-banner--${this.color()}`, `gw-banner--${this.size()}`];
  });
}
