import { Injectable } from '@angular/core';
import { ToastrService as NgxToastrService, ActiveToast } from 'ngx-toastr';

import { ToastModule } from './toast.module';

export interface IToastOption {
  timeOut: number;
  closeButton: boolean;
  positionClass: string;
}

@Injectable({
  providedIn: ToastModule,
})
export class ToastService {
  constructor(private readonly toastr: NgxToastrService) {}

  success(message: string, options: Partial<IToastOption> = {}) {
    return this.toastr.success(message, undefined, options);
  }

  error(message: string, options: Partial<IToastOption> = {}) {
    return this.toastr.error(message, undefined, options);
  }

  warn(message: string, options: Partial<IToastOption> = {}): ActiveToast<unknown> {
    return this.toastr.warning(message, undefined, options);
  }

  clear(toastId?: number) {
    this.toastr.clear(toastId);
  }

  remove(toastId: number) {
    this.toastr.remove(toastId);
  }
}
