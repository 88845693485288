import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';

export class AbstractDataSource<T> extends DataSource<T> {
  public data: BehaviorSubject<T[]>;

  constructor(dataSource: T[]) {
    super();
    this.data = new BehaviorSubject<T[]>(dataSource);
  }

  connect(): Observable<T[]> {
    return this.data;
  }

  disconnect() {}
}
