/**
 * The event emitted whenever the sort order gets updated.
 */
export interface ISortEvent<T> {
  // The column that is being sorted.
  column: keyof T;
  // The order of the sort, whether it is ascending or descending.
  order: SortOrders;
}

export enum SortOrders {
  Ascending = 'ascending',
  Descending = 'descending',
}
