<!-- 
  The combination of border-y-2 and custom vertical padding 3px matches the 5px padding and
  it is used to avoid a visual issue where the list elements are being displayed over the 
  host element inner shadow when the list becomes scrollable at max-height 320px
-->
<ul
  class="gw-dropdown__overlay__list gw-flex gw-max-h-full gw-flex-col gw-overflow-y-auto gw-rounded gw-border-y-2 gw-border-transparent gw-px-1 gw-py-[3px] gw-no-scrollbar"
  role="listbox"
  tabindex="-1"
>
  @for (group of optionGroups; track trackBy) {
    @if (group.groupName?.length) {
      <span
        class="gw-dropdown__overlay__list__group-name gw-p-1 gw-text-sm gw-font-medium gw-text-secondary-text"
      >
        {{ group.groupName }}
      </span>
    }
    @for (item of group.items; track trackBy) {
      <li
        class="gw-dropdown__overlay__list__item gw-h-6 gw-rounded gw-pb-1 gw-pl-2 gw-pr-1 gw-pt-1 gw-text-sm gw-outline-none"
        [ngClass]="{
          'gw-bg-grey-1': getItemValue(item) === activeItem,
          'gw-cursor-not-allowed': isItemDisabled(item),
          'gw-opacity-50': isItemDisabled(item),
          'hover:gw-cursor-pointer': !isItemDisabled(item),
          'hover:gw-bg-grey-1': !isItemDisabled(item)
        }"
        (click)="emitOptionSelected(item)"
        (keyup.enter)="emitOptionSelected(item)"
        aria-selected="false"
        role="option"
        tabindex="0"
        (focus)="setActiveItem(item)"
      >
        @if (optionsTemplate) {
          <ng-container
            [ngTemplateOutlet]="optionsTemplate"
            [ngTemplateOutletContext]="{ $implicit: item }"
          ></ng-container>
        } @else {
          {{ getItemValue(item) }}
          @if (getItemDescription(item)) {
            <span class="gw-dropdown__overlay__list__item__description gw-text-xs gw-text-secondary-text">
              {{ getItemDescription(item) }}
            </span>
          }
        }
      </li>
    }
  } @empty {
    <li
      class="gw-dropdown__overlay__list__item gw-h-6 gw-rounded gw-pb-1 gw-pl-2 gw-pr-1 gw-pt-1 gw-text-sm gw-text-secondary-text gw-outline-none"
      data-testid="empty-list-text"
    >
      {{ emptyListText }}
    </li>
  }
</ul>
