import { DatePipe } from '@angular/common';
import { Component, input } from '@angular/core';

import { FlowThumbnailContainerComponent } from './container/flow-thumbnail-container.component';
import { FlowThumbnailIconsComponent } from './icons/flow-thumbnail-icons.component';
import { TIconProps } from '../thumbnail.types';

/**
 * A thumbnail component for displaying a flow.
 *
 * @example
 * <gw-flow-thumbnail
 *  name="Timesheets into Xero"
 *  id="flo_4B8yPJoRLd5"
 *  updatedAt="2024-05-28T11:47:00.000Z"
 *  [integrationIcons]="[
 *   { src: 'assets/icons/logo-1.svg', alt: 'Logo 1' },
 *   { src: 'assets/icons/logo-2.svg', alt: 'Logo 2' },
 *  ]"
 * </gw-flow-thumbnail>
 */
@Component({
  selector: 'gw-flow-thumbnail',
  templateUrl: 'flow-thumbnail.component.html',
  standalone: true,
  imports: [DatePipe, FlowThumbnailContainerComponent, FlowThumbnailIconsComponent],
})
export class FlowThumbnailComponent {
  /**
   * The name of the flow.
   */
  public name = input.required<string>();
  /**
   * The ID of the flow.
   */
  public id = input.required<string>();
  /**
   * The date the flow was last updated in ISO 8601 format.
   */
  public updatedAt = input.required<string>();
  /**
   * The list of integration icons to display. Each icon should have a `src` and `alt` property.
   */
  public integrationIcons = input.required<TIconProps[]>();
}
