<gw-dropdown-input
  [showClearButton]="isClearable()"
  [disabled]="isComponentDisabled()"
  [placeholder]="placeholder()"
  [readOnly]="!isSearchable()"
  [value]="value"
  [active]="isOverlayAttached"
  (searchValueChange)="filterOptions($event)"
  (componentClick)="dropdownInputClick($event)"
></gw-dropdown-input>
