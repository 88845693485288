import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

/**
 * Global Web UI modal component.
 * @description The gw-modal component defines the padding, title, and dismiss icon for modals. Needs to be used with @angular/cdk dialog, see the components in modal-stories-helper-components for examples.
 *
 * @requires AngularCDK OverlayCSS from '@angular/cdk/overlay' to provide overlay styles.
 * This comes by default when you have imported the `styles.scss` file
 * from the Global Web UI library in your main styles.scss file
 * or in the angular.json file.
 * In case not, make sure to include the overlay styles in your main
 * styles.scss file by adding `@import '@angular/cdk/overlay-prebuilt.css';`
 *
 * @example Default - Dismiss button but no title, closeModal() is called when the dismiss button is clicked:
 * <gw-modal [dismiss]="true" (dismissEvent)="closeModal()">
 *
 * @example Modal with a title 'Upgrade now' and is not dismissable:
 * <gw-modal [title]="Upgrade now" [dismiss]="false">
 */
@Component({
  selector: 'gw-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  standalone: true,
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent {
  /**
   * This event is emitted when the dismiss button is clicked.
   */
  @Output() dismissEvent = new EventEmitter();
  /**
   * Whether or not the modal is dismissable.
   * If the modal is dismissable, then a button will be in the top right of the modal that emits dismissEvent when clicked.
   * @default true
   */
  @Input() dismissable = true;
  /**
   * Whether or not the close button is disabled.
   * @default false
   */
  @Input() disableCloseButton = false;
  /**
   * The title used in the top left of the modal.
   * If the title is blank then no title is displayed.
   * @default '';
   */
  @Input() title = '';

  emitDismissEvent() {
    if (this.disableCloseButton) {
      return;
    }
    this.dismissEvent.emit();
  }

  public isHeaderVisible() {
    return this.dismissable || this.title.length > 1;
  }
}
