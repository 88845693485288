<ul class="gw-flow-thumbnail__list gw-flex gw-gap-2">
  @for (integrationIcon of truncatedIntegrationIcons(); track integrationIcon.src) {
    <li class="gw-flow-thumbnail__list__item gw-flex gw-size-4 gw-items-center gw-justify-center">
      <img [src]="integrationIcon.src" [alt]="integrationIcon.alt" />
    </li>
  } @empty {
    <!-- In case there are no integration icons, this invisible li will keep the layout consistent -->
    <li
      class="gw-flow-thumbnail__list__item gw-flow-thumbnail__list__item--empty gw-invisible gw-size-4"
      aria-hidden="true"
    ></li>
  }
  @if (isListTruncated()) {
    <li
      class="gw-flow-thumbnail__list__item gw-flow-thumbnail__list__item--elapsed gw-text-xs gw-text-secondary-text"
    >
      ...
    </li>
  }
</ul>
