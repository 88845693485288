import { ConnectedPosition } from '@angular/cdk/overlay';

const DISTANCE_FROM_HOST = 8;
const INDICATOR_GAP = 18; // 10px for padding, 7px for half of the arrow width

export type TooltipIndicatorPosition =
  (typeof TooltipIndicatorPosition)[keyof typeof TooltipIndicatorPosition];

export const TooltipIndicatorPosition = {
  LeftTop: 'left-top',
  Left: 'left',
  LeftBottom: 'left-bottom',
  RightTop: 'right-top',
  Right: 'right',
  RightBottom: 'right-bottom',
  TopRight: 'top-right',
  Top: 'top',
  TopLeft: 'top-left',
  BottomLeft: 'bottom-left',
  Bottom: 'bottom',
  BottomRight: 'bottom-right',
} as const;

export const TooltipIndicatorPositionsMap: Record<TooltipIndicatorPosition, ConnectedPosition> = {
  [TooltipIndicatorPosition.LeftTop]: {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'top',
    panelClass: 'gw-tooltip--left-top',
    offsetX: DISTANCE_FROM_HOST,
    offsetY: -INDICATOR_GAP,
  },
  [TooltipIndicatorPosition.Left]: {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    panelClass: 'gw-tooltip--left',
    offsetX: DISTANCE_FROM_HOST,
  },
  [TooltipIndicatorPosition.LeftBottom]: {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'bottom',
    panelClass: 'gw-tooltip--left-bottom',
    offsetX: DISTANCE_FROM_HOST,
    offsetY: INDICATOR_GAP,
  },
  [TooltipIndicatorPosition.RightTop]: {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'top',
    panelClass: 'gw-tooltip--right-top',
    offsetX: -DISTANCE_FROM_HOST,
    offsetY: -INDICATOR_GAP,
  },
  [TooltipIndicatorPosition.Right]: {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    panelClass: 'gw-tooltip--right',
    offsetX: -DISTANCE_FROM_HOST,
  },
  [TooltipIndicatorPosition.RightBottom]: {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'bottom',
    panelClass: 'gw-tooltip--right-bottom',
    offsetX: -DISTANCE_FROM_HOST,
    offsetY: INDICATOR_GAP,
  },
  [TooltipIndicatorPosition.TopRight]: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    panelClass: 'gw-tooltip--top-right',
    offsetY: DISTANCE_FROM_HOST,
    offsetX: INDICATOR_GAP,
  },
  [TooltipIndicatorPosition.Top]: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    panelClass: 'gw-tooltip--top',
    offsetY: DISTANCE_FROM_HOST,
  },
  [TooltipIndicatorPosition.TopLeft]: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    panelClass: 'gw-tooltip--top-left',
    offsetY: DISTANCE_FROM_HOST,
    offsetX: -INDICATOR_GAP,
  },
  [TooltipIndicatorPosition.BottomLeft]: {
    originX: 'center',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    panelClass: 'gw-tooltip--bottom-left',
    offsetY: -DISTANCE_FROM_HOST,
    offsetX: -INDICATOR_GAP,
  },
  [TooltipIndicatorPosition.Bottom]: {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    panelClass: 'gw-tooltip--bottom',
    offsetY: -DISTANCE_FROM_HOST,
  },
  [TooltipIndicatorPosition.BottomRight]: {
    originX: 'center',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
    panelClass: 'gw-tooltip--bottom-right',
    offsetY: -DISTANCE_FROM_HOST,
    offsetX: INDICATOR_GAP,
  },
};
