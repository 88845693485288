<div class="text-input-switch" [ngSwitch]="variant">
  <div *ngSwitchCase="Variants.WithButton" class="text-input-wrapper">
    <div class="text-input-button-wrapper">
      <input
        #textInput
        class="text-input-with-button-icon {{ inputClass }}"
        [placeholder]="placeholder"
        [disabled]="isComponentDisabled"
        [(ngModel)]="value"
        (input)="onChange(value)"
        (blur)="onTouch()"
        type="text"
        [required]="required"
      />
      <button
        class="text-input-button-with-icon"
        (click)="handleClick($event, textInput)"
        [disabled]="isButtonDisabled"
      >
        <i class="text-input-button-icon cc-icon cc-icon-{{ buttonIcon }}"></i>
      </button>
    </div>
  </div>
  <div *ngSwitchCase="Variants.WithTextButton" class="text-input-wrapper gw-h-[36px]">
    <div class="text-input-button-wrapper">
      <input
        #textInput
        class="text-input-with-button-text {{ inputClass }}"
        [placeholder]="placeholder"
        [disabled]="isComponentDisabled"
        [(ngModel)]="value"
        (input)="onChange(value)"
        (blur)="onTouch()"
        type="text"
        [required]="required"
      />
      <button
        class="text-input-button-with-text {{ textButtonColor }}"
        (click)="handleClick($event, textInput)"
        [disabled]="isButtonDisabled"
      >
        <span class="text-input-button-text">{{ buttonText }}</span>
      </button>
    </div>
  </div>
  <div *ngSwitchCase="Variants.HiddenValue" class="hidden-value-wrapper">
    <input
      class="{{ inputClass }} hidden-value-input"
      [placeholder]="placeholder"
      [disabled]="isComponentDisabled"
      [(ngModel)]="value"
      (input)="onChange(value)"
      (blur)="onTouch()"
      [type]="getPasswordHidden() ? 'password' : 'text'"
      [required]="required"
    />
    <button
      [disabled]="isComponentDisabled"
      class="password-button"
      (click)="togglePasswordHidden()"
      (keydown.enter)="togglePasswordHidden()"
    >
      <i *ngIf="getPasswordHidden()" class="input-icon cc-icon cc-icon-eyeball-slash"></i>
      <i *ngIf="!getPasswordHidden()" class="input-icon cc-icon cc-icon-eyeball"></i>
    </button>
  </div>
  <div *ngSwitchDefault class="text-input-wrapper">
    <input
      [class]="inputClass"
      [placeholder]="placeholder"
      [disabled]="isComponentDisabled"
      [(ngModel)]="value"
      (input)="onChange(value)"
      (blur)="onTouch()"
      type="text"
      [required]="required"
    />
  </div>
</div>
