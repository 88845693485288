import { Component, input } from '@angular/core';

import { TIconProps } from '../../thumbnail.types';
import { FlowThumbnailContainerComponent } from '../container/flow-thumbnail-container.component';
import { FlowThumbnailIconsComponent } from '../icons/flow-thumbnail-icons.component';

/**
 * A thumbnail component for displaying a flow in the flow library.
 *
 * @example
 * <gw-flow-library-thumbnail
 *  name="Timesheets into Xero"
 *  description="Populate Xero Timesheets with data from Dashpivot."
 *  [selected]="false"
 *  [integrationIcons]="[
 *   { src: 'assets/icons/logo-1.svg', alt: 'Logo 1' },
 *   { src: 'assets/icons/logo-2.svg', alt: 'Logo 2' },
 *  ]"
 * </gw-flow-library-thumbnail>
 */
@Component({
  selector: 'gw-flow-library-thumbnail',
  templateUrl: 'flow-library-thumbnail.component.html',
  standalone: true,
  imports: [FlowThumbnailContainerComponent, FlowThumbnailIconsComponent],
})
export class FlowLibraryThumbnailComponent {
  /**
   * Whether the flow is selected.
   */
  public selected = input.required<boolean>();
  /**
   * The name of the flow.
   */
  public name = input.required<string>();
  /**
   * The description of the flow.
   */
  public description = input.required<string>();
  /**
   * The list of integration icons to display. Each icon should have a `src` and `alt` property.
   */
  public integrationIcons = input.required<TIconProps[]>();
}
