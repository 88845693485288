import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

/**
 * Global Web UI button component.
 * @description The gw-button attribute applies to native button elements and is used to style them.
 *
 * @example <caption>Default - Blue medium button with text "Click me":</caption>
 * <button gw-button color="blue" size="medium" text="Click me"></button>
 *
 * @example <caption>Gray small button with left and right icon and text "Close":</caption>
 * <button gw-button color="gray" size="small" text="Close" leftIcon="delete" rightIcon="clone">
 * </button>
 *
 * @example <caption>Use type checking to ensure the color is valid:</caption>
 * const buttonColor: ButtonComponent['color'] = 'blue';
 */
@Component({
  /**
   * TODO: this eslint rule is disabled because the selector is used as an attribute and not as a component
   * This needs to be fixed when we define a workflow for breaking changes
   */
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[gw-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ButtonComponent {
  @Input() color: 'blue' | 'green' | 'red' | 'white' | 'grey' | 'grey-2' | 'grey-3' | 'grey-9' = 'blue';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() leftIcon?: string;
  @Input() rightIcon?: string;
  @Input() text = '';
  /** additional classes to apply to the button. */
  @Input() class = '';

  /** calculated class based on the button props. */
  @HostBinding('class')
  get hostClass() {
    return `gw-${this.color} gw-${this.size} ${this.class}`;
  }
}
