/* eslint-disable max-lines */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ConnectedPosition,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  ComponentRef,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
  computed,
  effect,
  forwardRef,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { DropdownInputComponent } from './dropdown-input/dropdown-input.component';
import { DropdownOverlayComponent } from './dropdown-overlay/dropdown-overlay.component';
import { Identifiable } from '../shared/models/identifiable.model';
import { noop } from '../utils';

export interface IDropdownOptionProps extends Partial<Identifiable> {
  value: string;
  description?: string;
  isDisabled?: boolean;
  groupName?: string;
}

export type IDropdownOption = string | IDropdownOptionProps;

export interface IGroupedDropdownOption {
  groupName: string | null;
  items: IDropdownOption[];
}

/**
 * The dropdown component is used to display a list of options that the user can select from.
 * When the user clicks on the dropdown, a list of options will be displayed in an overlay.
 * The user can then select an option from the list.
 * The selected option will be displayed in the dropdown input.
 *
 * @requires AngularCDK OverlayCSS from '@angular/cdk/overlay' to provide overlay styles.
 * This comes by default when you have imported the `indx.scss` file
 * from the Global Web UI library in your main index.scss file
 * or in the angular.json file.
 * In case not, make sure to include the overlay styles in your main
 * styles.scss file by adding `@import '@angular/cdk/overlay-prebuilt.css';`
 *
 * @example <caption>ngModel bind:</caption>
 * <gw-dropdown [options]="dropdownOptions" [placeholder]="'$placeholder'" isClearable [disabled]="false" [(ngModel)]="selectedOption">
 * </gw-dropdown>
 *
 * @example <caption>Using with reactive form:</caption>
 * <gw-dropdown [options]="dropdownOptions" [placeholder]="'$placeholder'" [isClearable]="true" [disabled]="false" formControlName="formFieldNameExample">
 * </gw-dropdown>
 */
@Component({
  selector: 'gw-dropdown',
  templateUrl: 'dropdown.component.html',
  styleUrls: ['dropdown.component.scss'],
  standalone: true,
  imports: [DropdownInputComponent, DropdownOverlayComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownComponent<TOption extends IDropdownOption = IDropdownOption>
  implements OnDestroy, OnInit, ControlValueAccessor
{
  private readonly overlay = inject(Overlay);
  private readonly scrollStrategyOptions = inject(ScrollStrategyOptions);
  private readonly overlayPositionBuilder = inject(OverlayPositionBuilder);
  private overlayRef!: OverlayRef;
  public dropdownInputComponent = viewChild(DropdownInputComponent, { read: ElementRef });
  public dropdownInputComponentRef = viewChild(DropdownInputComponent, { read: DropdownInputComponent });
  public dropdownListRef: ComponentRef<DropdownOverlayComponent> | null = null;
  /**
   * The selected value of the dropdown.
   */
  public value?: string;
  /**
   * Whether the dropdown should display a clear button.
   */
  public isClearable = input(false, { transform: coerceBooleanProperty });
  /**
   * Whether the dropdown form control should be disabled.
   * This is used when the dropdown is used in a reactive form.
   * If using the component as part of a reactive form, you should control the disable state
   * through the form control itself, not through the component input (element attribute);
   * @example new FormControl({ value: '', disabled: true });
   */
  public controlDisabled = signal(false);
  /**
   * Whether the dropdown should be disabled.
   */
  public disabled = input(false, { transform: coerceBooleanProperty });
  /**
   * Whether the dropdown has the ability to be searched.
   */
  public isSearchable = input(false, { transform: coerceBooleanProperty });
  /**
   * The text to display when the dropdown list is empty.
   */
  public emptyListText = input('No options available');
  /**
   * The options to display in the dropdown.
   * TODO: Support for objects in next iterations to allow for the following features:
   * - icons (left, right and both sides)
   * - separators
   * - groups
   */
  public options = input<TOption[]>([]);
  /**
   * options converted to two-level objects so that items could be headed by their group names
   */
  public transformedOptions = computed(() => {
    const rawOptions = this.options();

    return this.transformOptions(rawOptions);
  });
  /**
   * An optional template to use for the dropdown options.
   * When using a template, the context of the template is the given IDropdownOption type.
   * @example
   * // In the component class:
   * public options = input<IDropdownOption[]>([{ value: 'Option 1' }, { value: 'Option 2' }]);
   * // In the template:
   * <gw-dropdown [options]="dropdownOptions" [optionsTemplate]="optionsTemplate"></gw-dropdown>
   * <ng-template #optionsTemplate let-option>
   *  <div>{{ option.value }}</div>
   * </ng-template>
   */
  public optionsTemplate = input<TemplateRef<IDropdownOption>>();
  /**
   * A function that can be used to filter the dropdown options.
   * This is useful if you want to filter the options based on a custom function.
   * @example
   * // In the component class:
   * public options = input<IDropdownOption[]>(['Option 1', 'Option 2', 'Option 3']);
   * public filteredOptions: IDropdownOption[] = [];
   * public customFilter(value: string) {
   *  const filterFn = (searchValue: string) => {
   *    this.filteredOptions = this.options().filter((option) => {
   *        return option.toLowerCase().includes(searchValue.toLowerCase());
   *    });
   *  }
   *  return filterFn;
   * }
   * // In the template:
   * <gw-dropdown [options]="filteredOptions" isSearchable [filterOptionsFunction]="customFilter"></gw-dropdown>
   */
  public filterOptionsFunction = input<(value: string) => void>();
  /**
   * The placeholder text to display when no value is selected.
   */
  public placeholder = input('Select...');
  /**
   * The width of the overlay panel.
   * @example '200px', 200, '100%', 'fit-content', etc.
   */
  public overlayPanelWidth = input<string | number>();
  /**
   * Emits the value of the dropdown input.
   */
  public searchValueChange = output<string>();
  public isComponentDisabled = computed(() => this.disabled() || this.controlDisabled());
  public onChange: (value: unknown) => void = noop;
  public onTouched = noop;

  constructor() {
    // This allows the dropdown options to be updated while the dropdown is open
    effect(() => {
      this.updateDropdownOptions(this.transformedOptions());
      this.updateDropdownEmptyListText(this.emptyListText());
    });
  }

  ngOnInit() {
    this.attachOverlay();
    window.addEventListener('click', (event) => this.closeOnClickOutside(event));
  }

  ngOnDestroy() {
    window.removeEventListener('click', (event) => this.closeOnClickOutside(event));
  }

  public attachOverlay() {
    /**
     * The overlay position strategy is used to position the overlay relative to the dropdown input.
     * It will display the overlay below the dropdown input when the dropdown is clicked.
     * Otherwise, it will display the overlay above the dropdown input.
     */
    const overlayPositions: ConnectedPosition[] = [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
      },
    ];

    const dropdownInputComponent = this.dropdownInputComponent()!;

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(dropdownInputComponent)
      .withPositions(overlayPositions);

    this.overlayRef = this.overlay.create({
      positionStrategy: positionStrategy,
      scrollStrategy: this.scrollStrategyOptions.block(),
      minWidth: dropdownInputComponent.nativeElement.clientWidth,
      width: this.overlayPanelWidth(),
      maxHeight: '320px',
      disposeOnNavigation: true,
    });
  }

  public closeOnClickOutside(event: MouseEvent) {
    // Early return to avoid unnecessary computations
    if (this.isComponentDisabled() || !this.isOverlayAttached) {
      return;
    }
    const target = event.target as HTMLElement;

    const internalElementsClassNames = [
      'gw-dropdown-input',
      'gw-dropdown-input__field',
      'gw-dropdown-input__actions',
      'gw-dropdown-input__clear-button',
      'gw-dropdown-input__arrow-button',
      'gw-dropdown__overlay__list',
      'gw-dropdown__overlay__list__group-name',
      'gw-dropdown__overlay__list__item',
      'gw-dropdown__overlay__list__item__description',
    ];

    const isClickedElementInternal = internalElementsClassNames.some((allowedClass) =>
      target.classList.contains(allowedClass),
    );

    // Check if the clicked element is part of the dropdown instance (including both the input and the overlay)
    const isSameDropdownInstance =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.dropdownInputComponent()?.nativeElement?.contains(target) ||
      this.overlayRef.overlayElement.contains(target);

    if (!isSameDropdownInstance || !isClickedElementInternal) {
      this.closeDropdown();
    }
  }

  public updateDropdownValue(value: string) {
    this.writeValue(value);
    this.onChange(value);
    this.onTouched();
  }

  public updateDropdownOptions(options: IGroupedDropdownOption[]) {
    if (this.dropdownListRef) {
      this.dropdownListRef.instance.optionGroups = options;
    }
  }

  public updateDropdownEmptyListText(emptyListText: string) {
    if (this.dropdownListRef) {
      this.dropdownListRef.instance.emptyListText = emptyListText;
    }
  }

  public dropdownInputClick(event: MouseEvent | KeyboardEvent) {
    if (this.isComponentDisabled()) {
      return;
    }

    const target = event.target as HTMLElement;
    const isOpen = this.isOverlayAttached;

    if (this.handleClearButtonClick(target)) {
      return;
    }

    if (this.handleInputClick(target, isOpen)) {
      return;
    }

    this.toggleOverlay(isOpen);
  }

  private handleClearButtonClick(target: HTMLElement): boolean {
    if (target.classList.contains('gw-dropdown-input__clear-button')) {
      this.clearDropdownInput();
      return true;
    }
    return false;
  }

  private handleInputClick(target: HTMLElement, isOpen: boolean): boolean {
    if (target.classList.contains('gw-dropdown-input__field')) {
      if (!isOpen) {
        this.openDropdown();
        return true;
      }
      if (this.isSearchable()) {
        return true;
      }
    }
    return false;
  }

  private toggleOverlay(isOpen: boolean): void {
    isOpen ? this.closeDropdown() : this.openDropdown();
  }

  /**
   * Opens the dropdown overlay.
   * If the overlay is already open, it will close it.
   * Passes the options to the dropdown overlay component.
   * Subscribes to the optionSelected event to set the value of the dropdown and close the overlay.
   * Subscribes to the backdropClick event to close the overlay when the backdrop (outside the overlay) is clicked.
   * @returns
   */
  public openDropdown() {
    if (this.isComponentDisabled() || this.isOverlayAttached) {
      return;
    }

    const listPortal = new ComponentPortal(DropdownOverlayComponent);

    this.dropdownListRef = this.overlayRef.attach(listPortal);

    this.dropdownListRef.instance.optionGroups = this.transformedOptions();
    this.dropdownListRef.instance.optionsTemplate = this.optionsTemplate();
    this.dropdownListRef.instance.emptyListText = this.emptyListText();
    this.dropdownListRef.instance.optionSelected.subscribe((option: string) => {
      this.updateDropdownValue(option);
      this.closeDropdown();
    });
  }

  public closeDropdown() {
    if (this.isOverlayAttached) {
      this.overlayRef.detach();
      this.dropdownInputComponentRef()?.clearSearchValue();
    }
  }

  public filterOptions(searchTerm: string) {
    this.searchValueChange?.emit(searchTerm);

    const customFilterFn = this.filterOptionsFunction();
    if (customFilterFn) {
      return customFilterFn(searchTerm);
    }

    // Internal filtering logic
    const filteredOptions = this.options().filter((option) => {
      if (typeof option === 'string') {
        return option.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return option.value.toLowerCase().includes(searchTerm.toLowerCase());
    });
    this.updateDropdownOptions(this.transformOptions(filteredOptions));
  }

  public clearDropdownInput() {
    this.updateDropdownValue('');
    this.updateDropdownOptions(this.transformedOptions());
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.controlDisabled.set(disabled);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  transformOptions(options: IDropdownOption[]): IGroupedDropdownOption[] {
    const groupless = options.filter((option) => typeof option === 'string' || !option.groupName);
    const groupedHash: { [key: string]: IDropdownOptionProps[] } = {};
    options.forEach((option) => {
      if (typeof option !== 'string' && option.groupName) {
        if (groupedHash[option.groupName]) {
          groupedHash[option.groupName].push(option);
        } else {
          groupedHash[option.groupName] = [option];
        }
      }
    });
    const grouped: IGroupedDropdownOption[] = Object.keys(groupedHash).map((groupName) => ({
      groupName,
      items: groupedHash[groupName],
    }));
    const transformed: IGroupedDropdownOption[] = [];
    if (groupless.length > 0) {
      transformed.push({
        groupName: null,
        items: groupless,
      });
    }
    return transformed.concat(grouped);
  }

  public get isOverlayAttached() {
    return this.overlayRef.hasAttached();
  }

  @HostBinding('class')
  public hostClass = 'gw-dropdown gw-w-full gw-inline-flex gw-flex-col gw-gap-1';
}
