<div
  class="gw-flow-thumbnail gw-flex gw-w-[var(--gw-flow-thumbnail-width)] gw-flex-col gw-gap-1 gw-rounded gw-p-2 gw-shadow-light-lg gw-ring-inset gw-transition-colors gw-duration-150 gw-ease-in-out hover:gw-cursor-pointer motion-reduce:gw-transition-none"
  [ngClass]="[backgroundClassNames, ringClassNames]"
>
  <p
    class="gw-flow-thumbnail__name gw-overflow-hidden gw-overflow-ellipsis gw-whitespace-nowrap gw-text-sm gw-font-bold"
  >
    {{ name() }}
  </p>
  <ng-content />
</div>
