import { NgClass } from '@angular/common';
import { computed, forwardRef, input, model, Component, OnInit, output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { noop } from '../utils';

export interface IToggleOption {
  text: string;
  icon: string;
  id: string;
}

export enum IToggleBackground {
  White = 'white',
  Grey_1 = 'grey-1',
  Grey_2 = 'grey-2',
  DefaultText = 'default-text',
}

/**
 * A standalone Angular component that renders a toggle button group.
 * Allows users to select from a set of options with optional icons.
 * The component can be styled with a background color and custom classes.
 * It emits an event when an option is selected.
 * This component implements the `ControlValueAccessor` interface to be used in Angular forms.
 *
 * @inputs
 *  - `options`: An array of `IToggleOption` objects to display as toggle options.
 *  - `value`: The id of the selected option. If not provided, the first option is selected by default.
 *  - `disabled`: A boolean indicating if the toggle component is disabled.
 *  - `background`: The background color of the toggle component, based on `IToggleBackground` enum.
 *  - `className`: Custom CSS classes to apply to the toggle component for additional styling.
 * @outputs
 *  - `valueChange`: Emits the id of the selected option.
 *
 * @example
 * <gw-state-toggle
 * [options]="[{id: 'option_1', text: 'State', icon: 'clone'}, {id: 'option_2', text: 'State', icon: 'clone'}]"
 * [disabled]="false"
 * [background]="'grey-1'"
 * (valueChange)="optionSelected($event)"
 * ></gw-state-toggle>
 */
@Component({
  selector: 'gw-state-toggle',
  templateUrl: './state-toggle.component.html',
  standalone: true,
  imports: [NgClass],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StateToggleComponent),
      multi: true,
    },
  ],
})
export class StateToggleComponent implements OnInit, ControlValueAccessor {
  /**
   * An array of `IToggleOption` objects to display as toggle options.
   */
  options = input.required<IToggleOption[]>();
  /**
   * A boolean indicating if the toggle component is disabled.
   */
  disabled = input<boolean>(false);
  /**
   * The background color of the toggle component, based on `IToggleBackground` enum.
   */
  background = input<IToggleBackground>(IToggleBackground.Grey_1);
  /**
   * Custom CSS classes to apply to the toggle component for additional styling.
   */
  className = input<string>('');
  /**
   * Event emitted when the value changes.
   */
  valueChange = output<string>();
  isComponentDisabled = computed(() => this.controlDisabled || this.disabled());
  colorClassNames = computed(() => {
    const disabledClassNames = 'disabled:gw-opacity-50 disabled:gw-cursor-not-allowed';
    const colorMap: Record<IToggleBackground, string> = {
      [IToggleBackground.White]:
        'gw-bg-white gw-text-default-text hover:gw-bg-grey-1 active:gw-bg-grey-3 disabled:gw-bg-grey-1',
      [IToggleBackground.Grey_1]:
        'gw-bg-grey-1 gw-text-default-text hover:gw-bg-grey-2 active:gw-bg-grey-4 disabled:gw-bg-grey-2',
      [IToggleBackground.Grey_2]:
        'gw-bg-grey-2 gw-text-default-text hover:gw-bg-grey-3 active:gw-bg-grey-5 disabled:gw-bg-grey-3',
      [IToggleBackground.DefaultText]:
        'gw-bg-grey-9 gw-text-grey-5 hover:gw-bg-grey-8 active:gw-bg-grey-7 disabled:gw-bg-grey-9',
    };

    return [disabledClassNames, colorMap[this.background()]];
  });
  public controlDisabled = false;
  public onChange: (value: unknown) => void = noop;
  public onTouched = noop;
  public value = model<string>();

  ngOnInit() {
    if (this.options().length > 0 && !this.value()) {
      this.writeValue(this.options()[0].id);
      this.onChange(this.options()[0].id);
    }
  }

  removeFocus() {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  optionSelected(value: string) {
    this.writeValue(value);
    this.onChange(value);
    this.removeFocus();
    this.valueChange.emit(value);
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.controlDisabled = disabled;
  }

  writeValue(value: string): void {
    this.value.set(value);
  }
}
