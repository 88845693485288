<div
  class="gw-state-toggle gw-flex gw-h-[36px] gw-w-full gw-min-w-fit gw-items-center gw-justify-center gw-rounded gw-text-sm gw-font-medium"
  [ngClass]="[className()]"
>
  @for (option of options(); track $index) {
    <button
      class="gw-state-toggle__option gw-group gw-relative gw-flex gw-size-full gw-min-w-fit gw-items-center gw-justify-center gw-gap-1 gw-px-2 focus:gw-outline-none aria-selected:gw-bg-default-blue aria-selected:gw-text-white"
      [ngClass]="value() === option.id ? '' : colorClassNames()"
      [disabled]="isComponentDisabled()"
      [ariaSelected]="value() === option.id"
      [class.gw-rounded-l]="$first"
      [class.gw-rounded-r]="$last"
      (keyup.enter)="optionSelected(option.id)"
      (click)="optionSelected(option.id)"
    >
      <div
        class="gw-state-toggle__option__outline gw-absolute -gw-z-10 gw-size-full gw-rounded-2 group-focus-within:gw-outline-none group-focus-within:gw-outline group-focus-within:gw-ring group-active:gw-outline-none group-active:gw-ring-0"
        [class.gw-rounded-l]="$first"
        [class.gw-rounded-r]="$last"
      ></div>
      @if (option.icon) {
        <i [ngClass]="'cc-icon cc-icon-' + option.icon" aria-hidden="true"></i>
      }
      <span class="gw-state-toggle__option__text">{{ option.text }}</span>
    </button>
  }
</div>
