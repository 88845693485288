import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

/**
 * A thumbnail component container for displaying a flow.
 *
 * @example
 * <gw-flow-thumbnail-container
 *  name="Timesheets into Xero"
 *  [selected]="true"
 * >
 *   <div> Some content </div>
 * </gw-flow-thumbnail-container>
 */
@Component({
  selector: 'gw-flow-thumbnail-container',
  templateUrl: 'flow-thumbnail-container.component.html',
  standalone: true,
  imports: [NgClass],
})
export class FlowThumbnailContainerComponent {
  /**
   * The name of the flow.
   */
  public name = input.required<string>();
  /**
   * Whether the flow is selected.
   */
  public selected = input<boolean>();

  /**
   * The ring class names for the block selection hover and selected states.
   */
  public get ringClassNames(): string {
    return this.selected()
      ? 'gw-ring gw-ring-default-blue'
      : 'gw-ring-2 gw-ring-transparent hover:gw-ring-grey-3';
  }

  /**
   * The background class names for the block selection hover and selected states.
   */
  public get backgroundClassNames(): string {
    return this.selected() ? 'gw-bg-default-light-blue' : 'gw-bg-white hover:gw-bg-grey-1';
  }
}
