<div class="toast__content">
  <div class="toast__icon">
    <span *ngIf="toastClasses.includes('toast-error') || toastClasses.includes('toast-warning')">
      <i class="cc-icon cc-icon-warning"></i>
    </span>
    <span *ngIf="toastClasses.includes('toast-success')">
      <i class="cc-icon cc-icon-check"></i>
    </span>
  </div>
  <div class="toast__message">
    {{ message }}
  </div>
  <div *ngIf="options.closeButton" class="toast__close-button">
    <button (keypress)="remove()">
      <i class="cc-icon cc-icon-close"></i>
    </button>
  </div>
</div>
