import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, HostBinding, output, TemplateRef } from '@angular/core';

import { IDropdownOption, IDropdownOptionProps, IGroupedDropdownOption } from '../dropdown.component';

/**
 * The dropdown overlay component.
 * This component is used to display the dropdown options.
 * It is used in the dropdown component and is tightly coupled with it to provide the dropdown functionality.
 */
@Component({
  selector: 'gw-dropdown-overlay',
  templateUrl: 'dropdown-overlay.component.html',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet],
})
export class DropdownOverlayComponent {
  public optionGroups: IGroupedDropdownOption[] = [];
  public activeItem: string | null = null;
  public emptyListText = '';
  public listClassName? = '';
  public listItemClassName? = '';
  public optionsTemplate?: TemplateRef<any>;
  public optionSelected = output<string>();

  public emitOptionSelected(option: IDropdownOption): void {
    const value = this.getValidValue(option);
    if (value) {
      this.optionSelected.emit(value);
    }
  }

  public setActiveItem(item: IDropdownOption): void {
    const value = this.getValidValue(item);
    if (value) {
      this.activeItem = value;
    }
  }

  public trackBy(_index: number) {
    return _index;
  }

  public getItemValue(item: IDropdownOption): string {
    return this.getItemProperty(item, 'value', item as string);
  }

  public getItemDescription(item: IDropdownOption): string | null {
    return this.getItemProperty(item, 'description', null);
  }

  public isItemDisabled(item: IDropdownOption): boolean {
    return this.getItemProperty(item, 'isDisabled', false);
  }

  private getValidValue(option: IDropdownOption): string | null {
    if (typeof option === 'string') {
      return option;
    } else if (!option.isDisabled) {
      return option.value;
    }
    return null;
  }

  private getItemProperty<T>(
    item: IDropdownOption,
    property: keyof IDropdownOptionProps,
    defaultValue: T,
  ): T {
    if (typeof item === 'string') {
      return defaultValue;
    }
    return (item[property] as T) || defaultValue;
  }

  @HostBinding('class')
  public hostClassName =
    'gw-dropdown__overlay gw-w-full gw-list-none gw-overflow-hidden gw-rounded gw-bg-white gw-shadow-dark-sm gw-ring-2 gw-ring-inset gw-ring-grey-3';
}
