import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkTableModule } from '@angular/cdk/table';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, input, output, signal, TemplateRef } from '@angular/core';

import { AbstractDataSource } from './abstract-data-table-source';
import { ISortEvent, SortOrders } from './sort.types';
import { ButtonComponent } from '../..';

type TCellTemplateMap<T> = Partial<Record<keyof T, TemplateRef<unknown>>>;

/**
 * The internal sorting state within the table used when sorting is enabled,
 * refer to SortEvent for the exposed event.
 */
interface InternalSortState {
  // Indicates the index based on 'sortableColumns' array on how the table should be sorted.
  activeIndex: number;
  // The current sort order of the column
  order: SortOrders;
}

export enum TextOverflow {
  WordBreak = 'word-break',
  Ellipsis = 'ellipsis',
}

/**
 * A generic data table component that can be used to display tabular data.
 * It uses the `CdkTableModule` to render the table.
 *
 * @type {T} The type of the data object that is displayed in the table.
 *
 * @example Default - The table will display the data in the `dataSource` array and the columns defined in the `displayedColumns` array:
 * <gw-data-table
 *  [dataSource]="[{ name: 'John', age: 30, city: 'New York' }, { name: 'Jane', age: 25, city: 'Los Angeles' }]"
 *  [displayedColumns]="['name', 'age', 'city']"
 * ></gw-data-table>
 *
 * @example With cell templates - The table will display the templates defined in the `cellTemplateMap` and `headerCellTemplateMap` objects:
 * <gw-data-table
 * [dataSource]="[{ name: 'John', age: 30, city: 'New York' }, { name: 'Jane', age: 25, city: 'Los Angeles' }]"
 * [displayedColumns]="['name', 'age', 'city']"
 * [headerCellTemplateMap]="{ name: nameHeaderCellTemplate }"
 * [cellTemplateMap]="{ age: ageCellTemplate }"
 * >
 *  <ng-template #nameHeaderCellTemplate let-data><strong>Header Value: {{ data }}</strong></ng-template>
 *  <ng-template #ageCellTemplate let-data let-index="index">
 *     <label>Age at row index-{{ index }}:</label>
 *    <input type="number" [(ngModel)]="data" />
 *  </ng-template>
 * </gw-data-table>
 *
 * @example Displaying a table with remove button and add button:
 * <gw-data-table
 * [dataSource]="[{ id: 1, name: 'John Doe' }, { id: 2, name: 'Jane Doe' }, { id: 3, name: 'Alice' }]"
 * [displayedColumns]="['id', 'name']"
 * [showAddButton]="true"
 * [showRemoveButton]="true"
 * ></gw-data-table>
 */
@Component({
  selector: 'gw-data-table',
  templateUrl: 'data-table.component.html',
  standalone: true,
  imports: [CdkTableModule, NgTemplateOutlet, NgClass, ButtonComponent],
  styleUrl: 'data-table.component.scss',
})
export class DataTableComponent<T> {
  /**
   * The gap between the title header and table.
   */
  public titleTableGap = input<string>('gw-gap-1');
  /**
   * A flag that determines if the table columns should have equal width.
   */
  public fixedLayout = input(false, { transform: coerceBooleanProperty });
  /**
   * The class name that is applied to the table element.
   */
  public tableClassName = input<string>('');
  /**
   * Ability to inject custom classes on individual columns.
   */
  public columnClassNames = input<Partial<Record<keyof T, string>>>({});
  /**
   * A flag that determines if each cells will have vertical borders between column.
   */
  public verticalGridLines = input(false, { transform: coerceBooleanProperty });
  /**
   * A flag that determines if the table will have borders around the left and right table edge.
   */
  public verticalEdgeLines = input(true, { transform: coerceBooleanProperty });
  /**
   * A flag that determines if the table will have horizontal borders between each row.
   */
  public horizontalGridLines = input(true, { transform: coerceBooleanProperty });
  /**
   * A flag that determines if the table will have a border around the top and bottom table edge.
   */
  public horizontalEdgeLines = input(true, { transform: coerceBooleanProperty });
  /**
   * The title of the table. It will appear above the table.
   */
  public title = input<string>();
  /**
   * A flag that determines if the add button is displayed. The add button appears below the table.
   */
  public showAddButton = input<boolean>(false);
  /**
   * A flag that determines if the remove button is displayed. The remove button appears on the top right corner of the table.
   */
  public showRemoveButton = input<boolean>(false);
  /**
   * The text that appears on the add button.
   */
  public addButtonText = input<string>('Add');
  /**
   * The text that appears on the remove button.
   */
  public removeButtonText = input<string>('Remove');
  /**
   * An output that is triggered when the remove button is clicked.
   */
  public removeClicked = output<void>();
  /**
   * An output that is triggered when the add button is clicked.
   */
  public addClicked = output<void>();
  /**
   * A flag that determines if the top row should be
   * hidden when there is no title or remove button.
   */
  public showTopRow = computed(() => this.title() || this.showRemoveButton());
  /**
   * A flag that determines the extra action bar header should be displayed.
   * Currently relies on content projection to display action bar content.
   */
  public showActionBar = input(false, { transform: coerceBooleanProperty });
  /**
   * A flag that determines if the row breaker section should be displayed.
   */
  public showRowBreaker = input(false, { transform: coerceBooleanProperty });
  /**
   * The text that appears in the row breaker section.
   */
  public rowBreakerText = input<string>('');
  /**
   * An array of objects of type `T` that is transformed into an `AbstractDataSource<T>`.
   */
  public dataSource = input.required<AbstractDataSource<T>, T[]>({
    transform: (data) => new AbstractDataSource<T>(data),
  });
  /**
   * Tracking function for determining changes to the data source.
   * Defining this allows rows (and underlying components) be updated without rebuilding template content.
   */
  public trackBy = input<(index: number, item: T) => unknown>((_, item) => item);
  /**
   * The columns that are displayed in the table.
   * - Each key should be a key of the data object.
   * - The order of the columns is defined by the order of the keys in the array.
   */
  public displayedColumns = input.required<(keyof T)[]>();
  /**
   * Define columns that are sortable, the first item in this array will be the default sort column.
   */
  public sortableColumns = input<(keyof T)[]>([]);
  /**
   * Dictates text overflow handling for table cells.
   */
  public textOverflow = input<TextOverflow>(TextOverflow.WordBreak);
  /**
   * Tracks the current sort state for tables that need sorting functionality.
   */
  public sort = signal<InternalSortState>({ order: SortOrders.Ascending, activeIndex: 0 });

  /**
   * Update the sort state for the table.
   *
   * @param column the column that is being sorted, choosing the same column toggles the sort order.
   */
  updateSortOrder(column: keyof T) {
    const { order: oldSortOrder, activeIndex: oldActiveIndex } = this.sort();
    const newActiveIndex = this.sortableColumns().indexOf(column);
    const newSortOrder =
      oldActiveIndex === newActiveIndex && oldSortOrder === SortOrders.Ascending
        ? SortOrders.Descending
        : SortOrders.Ascending;

    this.sort.set({ order: newSortOrder, activeIndex: newActiveIndex });
    this.sortUpdated.emit({ order: newSortOrder, column });
  }

  /**
   * Css classes applied to a specific column, based on the current sort state.
   *
   * @param column the column name to apply the css classes to.
   * @returns JSON object of css classes to render the correct sort icon based the specified column.
   */
  public sortIconClasses = (column: keyof T) => {
    const { order, activeIndex } = this.sort();
    const isSelectedColumn = activeIndex === this.sortableColumns().indexOf(column);

    return {
      'cc-icon gw-align-text-bottom gw-text-sm gw-cursor-pointer hover:gw-text-default-text': true,
      'gw-text-grey-5': !isSelectedColumn,
      'cc-icon-arrow-long-up': !isSelectedColumn || order === SortOrders.Ascending,
      'cc-icon-arrow-long-down': isSelectedColumn && order === SortOrders.Descending,
    };
  };
  /**
   * Event emitted whenever the sort order gets updated.
   */
  public sortUpdated = output<ISortEvent<T>>();
  /**
   * An object that defines the cell templates for each column, if no template is defined the raw value is displayed.
   */
  public cellTemplateMap = input<TCellTemplateMap<T>>({});
  /**
   * An object that defines the header cell templates for each column, if no template is defined the raw value is displayed.
   */
  public headerCellTemplateMap = input<TCellTemplateMap<T>>({});
  public cellTemplates = computed<Record<keyof T, TemplateRef<unknown> | null>>(() =>
    this.buildCellTemplates(this.cellTemplateMap()),
  );
  public headerCellTemplates = computed<Record<keyof T, TemplateRef<unknown> | null>>(() =>
    this.buildCellTemplates(this.headerCellTemplateMap()),
  );

  private buildCellTemplates(mapObject: TCellTemplateMap<T>) {
    return this.displayedColumns().reduce(
      (acc, column) => {
        acc[column] = mapObject[column] || null;
        return acc;
      },
      {} as Record<keyof T, TemplateRef<unknown> | null>,
    );
  }

  /**
   * The css classes applied to the table header cell.
   */
  public tableHeaderCellClasses = (column: keyof T) => {
    const hasExtraTableHeaders = this.showActionBar() || this.showRowBreaker();
    const headerClasses: Record<string, boolean> = {
      ...this.cellBorderClasses(),
      [`gw-bg-grey-1 gw-p-2 gw-text-left gw-text-sm gw-font-bold gw-capitalize ${this.generateColumnClassName(column)}`]:
        true,
      'rounded-top': !hasExtraTableHeaders,
      'gw-border-t': this.horizontalEdgeLines() && !hasExtraTableHeaders,
    };

    return this.includeExtraColumnClasses(headerClasses, column);
  };
  /**
   * The css classes applied to the table body cell.
   */
  public tableBodyCellClasses = (column: keyof T) => {
    const headerClasses: Record<string, boolean> = {
      ...this.cellBorderClasses(),
      [`gw-p-2 gw-text-left ${this.generateColumnClassName(column)}`]: true,
    };

    return this.includeExtraColumnClasses(headerClasses, column);
  };
  /**
   * The css class baseline applied to all table cells.
   */
  private readonly cellBorderClasses = computed(() => ({
    'gw-border-grey-3': true,
    'vertical-grid-lines': this.verticalGridLines(),
    'vertical-edge-lines': this.verticalEdgeLines(),
    'horizontal-grid-lines': this.horizontalGridLines(),
    'horizontal-edge-lines': this.horizontalEdgeLines(),
  }));

  /**
   * Generate the class name for a column based the column name.
   *
   * @param column suffix of the class name
   * @returns the generated class name
   */
  private generateColumnClassName(column: keyof T) {
    return `gw-column-${column.toString().toLowerCase().replace(/ /g, '-')}`;
  }

  /**
   * Include extra classes for the table cells based on columnClassName input
   *
   * @param classes the base classes for table cells
   * @param column the column name to apply the css classes to.
   * @returns transformed base classes with extra classes included if defined
   */
  private includeExtraColumnClasses(classes: Record<string, boolean>, column: keyof T) {
    const columnClassNames = this.columnClassNames()[column];
    if (columnClassNames) {
      classes[columnClassNames] = true;
    }
    return classes;
  }

  public textOverflowClass = computed(() => {
    switch (this.textOverflow()) {
      case TextOverflow.Ellipsis:
        return 'gw-text-truncate gw-overflow-hidden gw-text-ellipsis';
      case TextOverflow.WordBreak:
      default:
        return 'gw-break-words';
    }
  });
}
