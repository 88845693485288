import { NgClass } from '@angular/common';
import { Component, computed, input } from '@angular/core';

type AllowedLoaderSize = 10 | 20 | 30 | 40 | 50;

/**
 * A standalone Angular component that renders a spinner.
 * Allows users to select the size of the spinner.
 *
 * @inputs
 *  - `size`: A number specifying the size of the spinner. Allowed values are 10, 20, 30, 40, 50.
 *
 * @example Default - Spinner with default size of 30:
 * <gw-spinner />
 *
 * @example Spinner with size of 50:
 * <gw-spinner [size]="50" />
 */
@Component({
  selector: 'gw-spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class SpinnerComponent {
  /** Size of the spinner. Default size is 30, but allowed values are: 10, 20, 30, 40, 50 */
  size = input<AllowedLoaderSize>(30);
  spinnerSize = computed(() => `gw-spinner--${this.size()}`);
}
