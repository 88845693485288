import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { ToastComponent } from './toast.component';

@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
      positionClass: 'toast-top-center',
      closeButton: true,
      timeOut: 3500,
      preventDuplicates: true,
    }),
  ],
  providers: [provideAnimations()],
  declarations: [ToastComponent],
  exports: [ToastComponent],
})
export class ToastModule {}
