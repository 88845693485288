import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { noop } from '../utils';

/**
 * Global Web UI radio button component.
 * @component
 * @name RadioButtonComponent
 * @description
 * Radio button component for use in forms.
 *
 * @example
 * <gw-radio-button [(ngModel)]="isSelected" [label]="'Select me'"></gw-radio-button>
 *
 * @example
 * <form [formGroup]="form">
 *   <gw-radio-button formControlName="radioButtonControl" [label]="'Select me'"></gw-radio-button>
 * </form>
 *
 * @prerequisite
 * For template-driven forms, ensure `FormsModule` is imported.
 * For reactive forms, ensure `ReactiveFormsModule` is imported.
 */
@Component({
  selector: 'gw-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  standalone: true,
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent implements ControlValueAccessor {
  /**
   * An optional label to display next to the radio button.
   */
  @Input() label = '';
  /**
   * Whether the radio button is selected.
   */
  @Input() selected = false;
  /**
   * Event emitted when the selected state of the radio button changes.
   */
  @Output() selectedChange = new EventEmitter<boolean>();
  /* eslint-disable-next-line class-methods-use-this */
  private onChange: (value: boolean) => void = noop;
  /* eslint-disable-next-line class-methods-use-this */
  private onTouched = noop;

  writeValue(value: boolean): void {
    this.selected = value;
  }

  registerOnChange(fn: (_: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  toggleSelected() {
    this.selected = !this.selected;
    this.onChange(this.selected);
    this.onTouched();
    this.selectedChange.emit(this.selected);
  }
}
