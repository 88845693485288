import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'gw-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  standalone: true,
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.None,
})
export class BadgeComponent {
  @Input() text = '';
  @Input() type: 'info' | 'warning' | 'error' = 'info';
  @Input() color: 'blue' | 'light-blue' | 'light-green' | 'light-orange' | 'light-red' | 'grey-1' = 'blue';
  @Input() bold = false;
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() icon: string | undefined;
  @Input() disabled: boolean = false;
  @Input() size: 'small' | 'regular' = 'regular';
  @Input() customClasses = '';
  @Input()
  @HostBinding('class.inline-badge')
  isInlineBadge = false;
}
