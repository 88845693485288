import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, input, output, signal, TemplateRef } from '@angular/core';

import { SearchComponent } from '../search';
import { SpinnerComponent } from '../spinner';
import { TooltipDirective } from '../tooltip';

export enum Products {
  Dashpivot = 'Dashpivot',
  Flowsite = 'Flowsite',
  SitemateStart = 'Sitemate Start',
  Gearbelt = 'Gearbelt',
}

export interface IWorkspaceOption {
  id: string;
  name: string;
  accountName: string;
  product: Products;
  logo: IIconProps;
}

export interface IIconProps {
  src: string;
  alt: string;
}

export const ProductIcons: Record<Products, IIconProps> = {
  [Products.Dashpivot]: {
    src: 'assets/icons/multi-colored-icons/dashpivot-square.svg',
    alt: 'Dashpivot icon',
  },
  [Products.Flowsite]: {
    src: 'assets/icons/multi-colored-icons/flowsite-square-blue.svg',
    alt: 'Flowsite icon',
  },
  [Products.SitemateStart]: {
    src: 'assets/icons/multi-colored-icons/sitemate-start-square-blue.svg',
    alt: 'Sitemate Start icon',
  },
  [Products.Gearbelt]: {
    src: 'assets/icons/multi-colored-icons/gearbelt-square-blue.svg',
    alt: 'Gearbelt icon',
  },
};

/**
 * A multi-product workspace switcher component that includes a search input field.
 * Displays the list of workspaces from the input.
 * Emits an event when the search value changes and when a workspace is selected.
 * Actual workspace search will need to be implemented by the consumer.
 *
 * @example <caption> Default </caption>
 * <gw-cm-workspace-switcher [workspaces]="workspaces" />
 *
 * @example <caption> Custom Empty View </caption>
 * <gw-cm-workspace-switcher [workspaces]="workspaces" [emptyViewTemplate]="customEmptyTemplate" />
 *  <ng-template #customEmptyTemplate>
 *    <div class="gw-flex gw-flex-col gw-gap-2 gw-p-4 gw-text-center gw-text-secondary-text">
 *      <img src="/assets/images/no-results.svg" alt="No results" />
 *      <span>No workspaces found</span>
 *    </div>
 *  </ng-template>
 * </gw-cm-workspace-switcher>
 *
 */
@Component({
  selector: 'gw-cm-workspace-switcher',
  templateUrl: './workspace-switcher.component.html',
  standalone: true,
  styleUrls: ['./workspace-switcher.component.scss'],
  imports: [NgTemplateOutlet, NgIf, SearchComponent, TooltipDirective, SpinnerComponent],
})
export class WorkspaceSwitcherComponent<TOption extends IWorkspaceOption = IWorkspaceOption> {
  public ProductIcons = ProductIcons;
  public static readonly Products = Products;
  /**
   * Custom template to display when there are no workspaces. Otherwise, the default empty view is shown.
   */
  public emptyViewTemplate = input<TemplateRef<unknown> | null>(null);
  /**
   * The list of workspaces to display in the workspace switcher.
   */
  public workspaces = input<TOption[]>([]);
  /**
   * Whether to show the loading state.
   */
  public isLoading = input<boolean>(false);
  /**
   * The text to display when search text is empty.
   */
  public placeholderLabel = input<string>('Start searching for a workspace');
  /**
   * An event emitted when the search value changes.
   */
  public searchValueChange = output<string>();
  /**
   * An event emitted when a workspace is selected.
   */
  public workspaceSelected = output<TOption>();
  /**
   * Search value for filtering workspaces.
   */
  public searchValue = signal<string>('');

  public emitSearchValueChange(value: string) {
    this.searchValue.set(value);
    this.searchValueChange.emit(value);
  }

  public emitWorkspaceSelected(workspace: TOption) {
    this.workspaceSelected.emit(workspace);
  }
}
