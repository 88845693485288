import { NgClass } from '@angular/common';
import { Component, Signal, computed, input } from '@angular/core';

import { TIconProps } from '../thumbnail.types';

enum FlowBlockTypes {
  Trigger = 'trigger',
  Action = 'action',
}

/**
 * A thumbnail component for displaying a flow block.
 *
 * @example
 * <gw-flow-block-thumbnail
 * name="Form Signed"
 * type="trigger"
 * [icon]="{ src: 'assets/icons/multi-colored-icons/flow-thumbnail/dashpivot.svg', alt: 'Dashpivot icon' }"
 * [selected]="false"
 * ></gw-flow-block-thumbnail>
 */
@Component({
  selector: 'gw-flow-block-thumbnail',
  templateUrl: 'flow-block-thumbnail.component.html',
  standalone: true,
  imports: [NgClass],
})
export class FlowBlockThumbnailComponent {
  /**
   * Available types of flow blocks.
   */
  public static Type = FlowBlockTypes;
  /**
   * The type of the flow block.
   */
  public type = input.required<FlowBlockTypes>();
  /**
   * The name of the flow block.
   */
  public name = input.required<string>();
  /**
   * The icon of the flow block.
   */
  public icon = input.required<TIconProps>();
  /**
   * Whether the flow block is selected.
   */
  public selected = input(false);
  /**
   * The class names for the block type. The class names are computed based on the block type.
   */
  public blockTypeClassNames: Signal<{ container: string[]; icon: string }> = computed(() => {
    const base = 'gw-flow-block-thumbnail__type-container';
    const shape = 'gw-rounded gw-p-1';
    const defaultConfig = { container: [], icon: '' };

    const config = FlowBlockThumbnailComponent.typeConfigs[this.type()];
    if (!config) {
      return defaultConfig;
    }

    const modifier = `${base}--${this.type()}`;
    const icon = `cc-icon ${config.icon}`;
    const container = [base, modifier, shape, config.color];
    return { container, icon };
  });

  /**
   * The class names for the block selection hover and selected states.
   */
  public get ringClassNames(): string {
    return this.selected() ? 'gw-ring gw-ring-default-blue' : 'hover:gw-ring-2 hover:gw-ring-grey-4';
  }

  private static readonly typeConfigs: Record<FlowBlockTypes, { color: string; icon: string }> = {
    [FlowBlockTypes.Trigger]: {
      color: 'gw-bg-default-light-green gw-text-default-dark-green',
      icon: 'cc-icon-play',
    },
    [FlowBlockTypes.Action]: {
      color: 'gw-bg-default-light-blue gw-text-default-dark-blue',
      icon: 'cc-icon-zap',
    },
  };
}
