import { AnimationEvent, animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

/**
 * This component is used to display the tooltip text.
 * It is used by the TooltipDirective to attach it to
 * the DOM (overlay element ref) as a PortalComponent.
 */
@Component({
  selector: 'gw-tooltip-window',
  templateUrl: './tooltip-window.component.html',
  styleUrls: ['./tooltip-window.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule],
  animations: [
    trigger('fade', [
      transition(':enter', [style({ opacity: 0 }), animate('0.15s ease-in-out', style({ opacity: 1 }))]),
      state('hidden', style({ opacity: 0 })),
      transition('* => hidden', animate('0.15s ease-in-out')),
    ]),
  ],
})
export class TooltipWindowComponent {
  /**
   * The content to display in the tooltip window. Can be a string or a template reference.
   * It is accessed by the TooltipDirective using the directive's input property [gwTooltip].
   */
  @Input() content: string | TemplateRef<HTMLElement> = '';
  /**
   * Binds to the role attribute of the component.
   */
  @HostBinding('role') public role = 'tooltip';
  /**
   * Binds to the component class.
   * It is accessed by the TooltipDirective using the directive's input property [gwTooltipWindowClass].
   */
  @HostBinding('class')
  @Input()
  hostClass = '';
  /**
   * Binds to the container class.
   * It is accessed by the TooltipDirective using the directive's input property [gwTooltipContainerClass].
   */
  @Input() containerClass = '';
  /**
   * Emits an event when the tooltip window is closed.
   */
  @Output() windowClose = new EventEmitter<void>();
  @HostBinding('@fade') animationState = '';

  @HostListener('@fade.done', ['$event']) done(event: AnimationEvent) {
    if (event.toState === 'hidden') {
      this.windowClose.emit();
    }
  }

  public get containerClassnames(): string[] {
    return ['gw-tooltip__container', this.containerClass];
  }

  public get isTemplateContent(): boolean {
    return this.content instanceof TemplateRef;
  }

  public get templateContent(): TemplateRef<HTMLElement> {
    return this.content as TemplateRef<HTMLElement>;
  }
}
