<div class="gw-workspace-switcher__header gw-border gw-border-grey-3 gw-bg-grey-2 gw-p-1">
  <gw-search (searchValueChange)="emitSearchValueChange($event)" />
</div>

@if (isLoading()) {
  <div class="gw-workspace-switcher__loading">
    <gw-spinner [size]="30" />
  </div>
} @else if (workspaces().length > 0) {
  <ul class="gw-workspace-switcher__list">
    @for (item of workspaces(); track item.id) {
      <li
        class="gw-workspace-switcher__list__item gw-flex gw-items-center gw-gap-2 gw-bg-white"
        (click)="emitWorkspaceSelected(item)"
        (keyup.enter)="emitWorkspaceSelected(item)"
        tabindex="0"
      >
        <div class="gw-workspace-switcher__list__item--avatar gw-rounded">
          <img [src]="item.logo.src" [alt]="item.logo.alt" />
        </div>
        <div class="gw-workspace-switcher__list__item--title gw-flex gw-min-w-0 gw-flex-col">
          <span class="gw-truncate gw-text-sm gw-font-medium gw-text-default-text">{{ item.name }}</span>
          <span class="gw-truncate gw-text-xs gw-text-secondary-text">{{ item.accountName }}</span>
        </div>

        <div class="gw-workspace-switcher__list__item--product gw-text-xs">
          <img
            class="gw-workspace-switcher__list__item--product-icon"
            [src]="ProductIcons[item.product].src"
            [alt]="ProductIcons[item.product].alt"
            [gwTooltip]="item.product + ' workspace'"
            gwTooltipIndicatorPosition="top"
            gwTooltipWindowClass="product-tooltip-window"
            gwTooltipContainerClass="product-tooltip-container"
          />
        </div>
      </li>
    }
  </ul>
} @else if (searchValue()) {
  <div class="gw-workspace-switcher__empty">
    <ng-container *ngIf="emptyViewTemplate(); else defaultEmptyView">
      <ng-container [ngTemplateOutlet]="emptyViewTemplate()"></ng-container>
    </ng-container>
  </div>
} @else if (placeholderLabel()) {
  <div class="gw-workspace-switcher__empty gw-text-sm gw-text-secondary-text">
    <span>{{ placeholderLabel() }}</span>
  </div>
}

<ng-template #defaultEmptyView>
  <span class="gw-text-sm gw-text-secondary-text">No workspaces found</span>
</ng-template>
