import { NgClass } from '@angular/common';
import {
  Component,
  computed,
  DestroyRef,
  ElementRef,
  HostBinding,
  inject,
  input,
  OnInit,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, Subject } from 'rxjs';

/**
 * A search component that includes a text input field and a clear button.
 * The clear button is only visible when the text input field has a value.
 * Emits an event when the search value changes.
 *
 * Sizing: Height will hug content, width will be 100% of parent container with min width of 300px.
 *
 * @example <caption> Default - White </caption>
 * <gw-search />
 *
 * @example <caption> Grey - placeholder </caption>
 * <gw-search color="grey" placeholder="Search for items..." />
 *
 * @example <caption> Disabled </caption>
 * <gw-search [disabled]="true" />
 */
@Component({
  selector: 'gw-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class SearchComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  public inputElement = viewChild.required<ElementRef<HTMLInputElement>>('gwSearchInputElement');
  /**
   * Specifies which color scheme to use for the search component.
   */
  public color = input<'white' | 'grey'>('white');
  /**
   * Whether the component should be disabled.
   */
  public disabled = input<boolean>(false);
  /**
   * Placeholder text to include in the search input.
   */
  public placeholder = input<string>('Search...');
  /**
   * An event emitted when the search value changes.
   */
  public searchValueChange = output<string>();
  /**
   * Specifies the debounce time for emitting the search value change event.
   * Default is 300ms.
   */
  public debounceTime = input<number>(300);
  /**
   * Additional classes to apply to the search input.
   */
  public textInputClass = input<string>('');
  public searchValue = signal<string>('');
  private readonly searchValueSubject$ = new Subject<string>();

  @HostBinding('class')
  get hostClass() {
    const active = this.searchValue() ? 'gw-search--active' : '';
    const disabled = this.disabled() ? 'gw-search--disabled' : '';
    return ['gw-search', `gw-search--${this.color()}`, active, disabled].join(' ');
  }

  ngOnInit(): void {
    this.searchValueSubject$
      .pipe(debounceTime(this.debounceTime()), takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.searchValueChange.emit(value);
      });
  }

  public showClearButton = computed(() => !!this.searchValue() && !this.disabled());

  public emitSearchValueChange(event: Event) {
    const { value } = event.target as HTMLInputElement;
    this.updateSearchValue(value);
  }

  public clearSearchValue() {
    this.updateSearchValue('');
  }

  public clearButtonClick(event: MouseEvent | Event) {
    if (event instanceof KeyboardEvent) {
      event.preventDefault();
      if (event.key !== 'Enter') {
        return;
      }
    }

    this.clearSearchValue();
    this.inputElement().nativeElement.focus();
  }

  public updateSearchValue(value: string) {
    this.searchValue.set(value);
    this.searchValueSubject$.next(value);
  }
}
