<div [ngClass]="containerClass">
  <div
    (keydown.enter)="toggle()"
    (keydown.space)="toggle()"
    (click)="toggle()"
    tabindex="0"
    role="button"
    [attr.aria-expanded]="isOpen()"
  >
    <!-- Custom Header via ng-template -->
    <ng-container *ngIf="headerTemplate; else defaultHeader">
      <ng-container
        [ngTemplateOutlet]="headerTemplate"
        [ngTemplateOutletContext]="{
          isOpen: isOpen(),
          toggle: toggle.bind(this)
        }"
      ></ng-container>
    </ng-container>

    <ng-template #defaultHeader>
      <div class="gw-flex gw-justify-between gw-p-2">
        <div class="gw-select-none">
          <h5 class="gw-inline-block gw-text-sm gw-font-bold">{{ title }}</h5>
          <span class="gw-inline-block gw-pl-1 gw-text-sm gw-text-secondary-text" *ngIf="subtitle">{{
            subtitle
          }}</span>
        </div>
        <i
          *ngIf="!hideToggle"
          class="gw-content-center gw-py-0.4 gw-px-0.4 hover:gw-bg-grey-2 gw-rounded cc-icon cc-icon-arrow-{{
            isOpen() ? 'down' : 'right'
          }}"
        ></i>
      </div>
    </ng-template>
  </div>
  <div *ngIf="isOpen()" class="gw-p-2">
    <ng-content></ng-content>
  </div>
</div>
