import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';

import { ButtonComponent } from '../../button/button.component';
import { TIconProps } from '../thumbnail.types';

/**
 * A thumbnail component for displaying a connection.
 *
 * @example
 * <gw-connection-thumbnail
 * name="Form Signed"
 * type="trigger"
 * [icon]="{ src: 'assets/icons/multi-colored-icons/connection-thumbnail/dashpivot.svg', alt: 'Dashpivot icon' }"
 * [selected]="false"
 * ></gw-connection-thumbnail>
 */
@Component({
  selector: 'gw-connection-thumbnail',
  templateUrl: 'connection-thumbnail.component.html',
  standalone: true,
  imports: [NgClass, ButtonComponent],
})
export class ConnectionThumbnailComponent {
  /**
   * The name of the connection block.
   */
  public name = input.required<string>();
  /**
   * The icon of the connection block.
   */
  public icon = input.required<TIconProps>();
  /**
   * The description of the connection block.
   */
  public description = input.required<string>();
  /**
   * The event emitted when the button is pressed.
   */
  public buttonClicked = output<string>();

  public handleClick(): void {
    this.buttonClicked.emit(this.name());
  }
}
