import { NgClass } from '@angular/common';
import {
  Component,
  computed,
  ElementRef,
  input,
  output,
  signal,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'gw-dropdown-input',
  templateUrl: 'dropdown-input.component.html',
  styleUrls: ['dropdown-input.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgClass],
})
export class DropdownInputComponent {
  public inputElement = viewChild<ElementRef<HTMLInputElement>>('gwDropdownInputElement');
  /**
   * The placeholder of the dropdown input.
   */
  public placeholder = input('');
  /**
   * The value of the dropdown input.
   * This is used to display the selected option in the input.
   */
  public value = input<string>();
  /**
   * The search value of the dropdown input.
   * This is used to filter the options in the dropdown.
   */
  public searchValue = signal<string>('');
  /**
   * Whether the dropdown input is clearable or not.
   * This is used to show a clear button in the input.
   */
  public showClearButton = input(false);
  /**
   * Whether the dropdown input is disabled or not.
   */
  public disabled = input(false);
  /**
   * Whether the dropdown input is read-only or not.
   * This happens when the dropdown is not searchable.
   */
  public readOnly = input(false);
  /**
   * Whether the dropdown input is active (when overlay is open) or not.
   */
  public active = input(false);
  /**
   * Emits the value of the dropdown input.
   */
  public searchValueChange = output<string>();
  /**
   * Emits when the input is clicked.
   */
  public componentClick = output<MouseEvent | KeyboardEvent>();

  public emitSearchValueChange(event: Event) {
    const { value } = event.target as HTMLInputElement;
    this.updateAndEmitSearchValueChange(value);
  }

  public clearSearchValue() {
    this.updateAndEmitSearchValueChange('');
  }

  public clearButtonClick(event: MouseEvent | KeyboardEvent) {
    if (event instanceof KeyboardEvent) {
      event.preventDefault();
      if (event.key !== 'Enter') {
        return;
      }
    }
    this.clearSearchValue();
    this.inputElement()?.nativeElement.focus();
  }

  public updateAndEmitSearchValueChange(value: string) {
    this.searchValue.set(value);
    this.searchValueChange.emit(value);
  }

  public containerClass = computed(() => ({
    'gw-dropdown-input--disabled': this.disabled(),
    'gw-dropdown-input--active': this.active(),
    'gw-dropdown-input--read-only': this.readOnly(),
  }));
}
