@if (showContainer()) {
  <div class="gw-counter-container">
    <ng-container *ngTemplateOutlet="counter" />
  </div>
} @else {
  <ng-container *ngTemplateOutlet="counter" />
}

<ng-template #counter>
  <span class="gw-counter {{ badgeClass() }}">{{ badgeValue() }}</span></ng-template
>
