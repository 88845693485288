<div
  class="gw-dropdown-input"
  [ngClass]="containerClass()"
  (click)="componentClick.emit($event)"
  (keyup)="componentClick.emit($event)"
  tabindex="-1"
>
  <input
    #gwDropdownInputElement
    class="gw-dropdown-input__field gw-w-full"
    [readOnly]="readOnly()"
    [placeholder]="placeholder()"
    [value]="searchValue() || value()"
    [disabled]="disabled()"
    (input)="emitSearchValueChange($event)"
    tabindex="0"
  />
  <div class="gw-dropdown-input__actions">
    @if (showClearButton()) {
      <i
        class="gw-dropdown-input__clear-button cc-icon cc-icon-close gw-cursor-pointer"
        (click)="clearButtonClick($event)"
        (keyup)="clearButtonClick($event)"
        tabindex="0"
      ></i>
    }
    <i
      class="gw-dropdown-input__arrow-button cc-icon cc-icon-arrow-down-small gw-cursor-pointer"
      tabindex="0"
    ></i>
  </div>
</div>
