import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  signal,
} from '@angular/core';

/**
 * Global Web UI collapsible component.
 * @description The `gw-collapsible` component displays a section that can expand and collapse, you can also override the header template for more advanced usage.
 *
 * @example <caption>Basic collapsible</caption>
 * <gw-collapsible title="Pending" subtitle="(1)">
 *   <p>body content here</p>
 * </gw-collapsible>
 *
 * @example <caption>Collapsible with custom header template:</caption>
 * <gw-collapsible>
 *   <ng-template #collapsibleHeader>
 *     <div class="custom-header">Custom Header Content</div>
 *   </ng-template>
 *   <p>body content here</p>
 * </gw-collapsible>
 */
@Component({
  selector: 'gw-collapsible',
  templateUrl: './collapsible.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true,
})
export class CollapsibleComponent {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() isOpen = signal(false);
  @Input() hideToggle: boolean = false; // Arrow icon visibility
  @Input() containerClass: string = 'gw-bg-grey-1 gw-rounded';
  @Output() toggled = new EventEmitter<boolean>();
  @ContentChild('collapsibleHeader', { static: false }) headerTemplate!: TemplateRef<any>;

  constructor(private cdr: ChangeDetectorRef) {}

  toggle() {
    const currentState = this.isOpen();
    this.isOpen.set(!currentState);
    this.toggled.emit(!currentState);
  }

  setOpenState(open: boolean) {
    this.isOpen.set(open);
    this.toggled.emit(open);
  }
}
