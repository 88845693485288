<div class="gw-modal">
  <div *ngIf="isHeaderVisible()" class="gw-modal-header">
    <span class="gw-modal-title">{{ title }}</span>
    <button
      *ngIf="dismissable"
      [disabled]="disableCloseButton"
      class="close-button"
      (click)="emitDismissEvent()"
    >
      <i class="cc-icon cc-icon-close"></i>
    </button>
  </div>
  <ng-content></ng-content>
</div>
