import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { noop } from '../utils';

export enum ITextInputVariant {
  HiddenValue = 'Hidden Value',
  WithButton = 'With Button',
  WithTextButton = 'With Text Button',
  Base = 'Base',
}

/**
 * TextInputComponent provides a text input field in the following variants
 * Sizing: Height will hug content, width will be 100% of parent container
 *
 * @example <caption> Standard variant - formless input</caption>
 * <gw-text-input [placeholder]="Text..." [(ngModel)]="yourValue"></gw-text-input>
 *
 * @example <caption> Standard variant - ngForm</caption>
 * <gw-text-input name="input1" [(ngModel)]="formState.input1" placeholder="Text..."></gw-text-input>
 *
 * @example <caption> Standard variant - reactive form</caption>
 * <gw-text-input formControlName="billingCompany"></gw-text-input>
 *
 * @example <caption> Hidden value variant</caption>
 * <gw-text-input variant="Variants.HiddenValue" [placeholder]="Hidden Text..."></gw-text-input>
 *
 * @example <caption> Button variant - outputs the button clicked event</caption>
 * <gw-text-input variant="Variants.WithButton" buttonIcon="clone" [placeholder]="Text..." [buttonClicked]="someFunction"></gw-text-input>
 */
@Component({
  selector: 'gw-text-input',
  standalone: true,
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
  imports: [FormsModule, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault],
})
export class TextInputComponent implements ControlValueAccessor, OnInit {
  private _isHidden = false;
  /** value of the text input. */
  public value = '';
  /**
   * Whether the text input form control should be disabled.
   * This is used when the text input is being used in a reactive form.
   * If using the component as part of a reactive form, the disabled state is controlled
   * through the form control itself
   * @example new FormControl({ value: '', disabled: true });
   */
  public controlDisabled = false;
  /** placeholder text to include in the text input. */
  @Input() placeholder = '';
  /** additional classes to apply to the text input. */
  @Input() class = '';
  /** set whether text input is disabled. If using this component
   * as part of a reactive form, do not control disabled state
   * through this input */
  @Input() disabled = false;
  /** set whether text input is required */
  @Input() required = false;
  /** the icon to use for the button variant */
  @Input() buttonIcon = '';
  /** the text to show on the button (if using ITextInputVariant.WithTextButton) */
  @Input() buttonText = '';
  /** whether the button should be disabled or not */
  @Input() isButtonDisabled = false;
  /** the variant of the text input, base is default */
  @Input() variant: ITextInputVariant = ITextInputVariant.Base;
  /** Outputs the event generated on click */
  @Output() buttonClicked = new EventEmitter<{ event: MouseEvent; inputTarget: HTMLInputElement }>();

  /** returns true if a button variant icon has been provided, indicating that component is to be a button variant */
  get buttonVariant(): boolean {
    return this.buttonIcon ? true : false;
  }

  // OK to disable, we want the getter to return the enum declared in this file
  // eslint-disable-next-line class-methods-use-this
  public get Variants(): typeof ITextInputVariant {
    return ITextInputVariant;
  }

  /** generates the class to apply to the input */
  get inputClass() {
    return `gw-text-input ${this.class}`;
  }

  get textButtonColor() {
    return this.isButtonDisabled ? 'gw-bg-grey-4' : 'gw-bg-default-green';
  }

  /** Toggles the _isHidden value between true/false. Used to set the input field after render. */
  togglePasswordHidden(): void {
    this._isHidden = !this._isHidden;
  }

  /** Retrieves the _isHidden value. Used to set the input field after render. */
  getPasswordHidden(): boolean {
    return this._isHidden;
  }

  /** Checks whether component should be in disabled state */
  public get isComponentDisabled() {
    return this.disabled || this.controlDisabled;
  }

  ngOnInit() {
    this._isHidden = this.variant === ITextInputVariant.HiddenValue;
  }

  onChange: (value: string) => void = noop;
  onTouch = noop;

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.controlDisabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  handleClick(event: MouseEvent, input: HTMLInputElement): void {
    this.buttonClicked.emit({ event, inputTarget: input });
  }
}
