<div [ngClass]="['gw-flex gw-flex-col gw-text-sm', titleTableGap()]">
  @if (showTopRow()) {
    <div class="gw-flex gw-items-center gw-justify-between" data-testid="top-container">
      <span data-testid="table-title" [class.gw-hidden]="!title()" class="gw-text-sm">{{ title() }}</span>
      @if (showRemoveButton()) {
        <button
          class="gw-group gw-flex gw-cursor-pointer gw-items-center gw-gap-1 gw-outline-none"
          (click)="removeClicked.emit()"
          data-testid="remove-button"
        >
          <i class="cc-icon cc-icon-close gw-text-default-red"></i>
          <span class="gw-text-sm group-focus:gw-underline">{{ removeButtonText() }}</span>
        </button>
      }
    </div>
  }
  <div class="gw-table-section">
    @if (showActionBar()) {
      <div class="gw-border gw-border-grey-3 gw-bg-grey-2 gw-px-2 gw-py-1" data-testid="action-bar">
        <ng-content select="action-bar"></ng-content>
      </div>
    }
    @if (showRowBreaker()) {
      <div
        class="gw-flex gw-h-6 gw-items-center gw-border gw-border-t-0 gw-border-solid gw-border-grey-3 gw-bg-grey-2 gw-px-2 gw-font-bold"
        data-testid="row-breaker"
      >
        <p>{{ rowBreakerText() }}</p>
      </div>
    }
    <table
      cdk-table
      [dataSource]="dataSource()"
      [trackBy]="trackBy()"
      [ngClass]="[
        'gw-data-table gw-border-separate gw-border-spacing-0 gw-rounded gw-bg-white',
        tableClassName()
      ]"
      [fixedLayout]="fixedLayout()"
    >
      @for (col of displayedColumns(); track col; let f = $first) {
        <ng-container [cdkColumnDef]="col.toString()">
          <th cdk-header-cell *cdkHeaderCellDef [ngClass]="tableHeaderCellClasses(col)">
            <div class="gw-flex gw-items-center gw-gap-2">
              @if (headerCellTemplates()[col]) {
                <ng-container
                  [ngTemplateOutlet]="headerCellTemplates()[col]"
                  [ngTemplateOutletContext]="{ $implicit: col }"
                ></ng-container>
              } @else {
                {{ col }}
              }
              @if (sortableColumns().includes(col)) {
                <button
                  type="button"
                  class="focus-visible:gw-border"
                  (click)="updateSortOrder(col)"
                  (keydown.enter)="updateSortOrder(col)"
                >
                  <i [ngClass]="sortIconClasses(col)" aria-hidden="true"></i>
                </button>
              }
            </div>
          </th>
          <td cdk-cell *cdkCellDef="let row; let i = index" [ngClass]="tableBodyCellClasses(col)">
            @if (cellTemplates()[col]) {
              <ng-container
                [ngTemplateOutlet]="cellTemplates()[col]"
                [ngTemplateOutletContext]="{ $implicit: row[col], row, index: i }"
              ></ng-container>
            } @else {
              <div [ngClass]="textOverflowClass()">{{ row[col] }}</div>
            }
          </td>
        </ng-container>
      }
      <tr cdk-header-row *cdkHeaderRowDef="displayedColumns()"></tr>
      <tr cdk-row *cdkRowDef="let row; columns: displayedColumns()"></tr>
    </table>
  </div>
  @if (showAddButton()) {
    <button
      gw-button
      color="blue"
      (click)="addClicked.emit()"
      class="gw-w-fit"
      [text]="addButtonText()"
      data-testid="add-button"
    ></button>
  }
</div>
