import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, input, ViewEncapsulation } from '@angular/core';

enum BadgeStatus {
  Default = 'default',
  Warning = 'warning',
  Full = 'full',
}

/**
 * The CounterComponent is used to display a count and visually indicate
 * how close the count is to a specified limit. The component can display
 * three different statuses: 'Default', 'Warning', and 'Full', which correspond
 * to the relationship between the current count and the limit.
 *
 * The component is highly configurable and allows for different warning thresholds.
 *
 * @example
 * <gw-limit-counter [current]="10" [limit]="15" [warningPercentage]="75" [showContainer]="true"></gw-limit-counter>
 *
 * @input current {number} - The current count to be displayed and evaluated against the limit.
 * @input limit {number} - The maximum limit for the count. Once the current count reaches this limit, the status changes to 'Full'.
 * @input warningPercentage {number} - The percentage of the limit at which the component will switch to a 'Warning' status.
 * @input showContainer {boolean} - If true, wraps the counter in a container with additional styling.
 */
@Component({
  selector: 'gw-limit-counter',
  templateUrl: './limit-counter.component.html',
  styleUrls: ['./limit-counter.component.scss'],
  imports: [NgTemplateOutlet],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class LimitCounterComponent {
  current = input<number>();
  limit = input<number>();
  showContainer = input<boolean>();
  warningPercentage = input<number>();
  badgeValue = computed(() => this.setBadgeValue());
  badgeClass = computed(() => this.applyBadgeStatus());

  private applyBadgeStatus(): string {
    const current = this.parseNumber(this.current());
    const warningPercentage = this.parseNumber(this.warningPercentage());
    const limit = this.parseNumber(this.limit());
    let badge: BadgeStatus;

    if (current >= limit) {
      badge = BadgeStatus.Full;
    } else {
      const limitStep = 1 / limit;
      const warningThreshold = warningPercentage / 100 / limitStep;
      badge = current >= Math.floor(warningThreshold) ? BadgeStatus.Warning : BadgeStatus.Default;
    }

    const classList = [`gw-counter--${badge}`];

    if (!this.showContainer()) {
      classList.push('gw-text-sm', 'gw-font-bold');
    }

    return classList.join(' ');
  }

  private setBadgeValue(): string {
    const current = this.parseNumber(this.current());
    const limit = this.parseNumber(this.limit());

    return `${current}/${limit}`;
  }

  private parseNumber(value: unknown): number {
    return Number.isNaN(Number(value)) ? 0 : Number(value);
  }
}
