<div
  class="gw-flow-block-thumbnail gw-flex gw-w-[300px] gw-cursor-pointer gw-items-center gw-gap-2 gw-rounded gw-bg-white gw-p-4 gw-leading-0 gw-shadow-light-lg gw-ring-inset gw-transition hover:gw-bg-grey-1"
  [ngClass]="ringClassNames"
>
  <div [ngClass]="blockTypeClassNames().container">
    <i [ngClass]="blockTypeClassNames().icon"></i>
  </div>
  <div class="gw-flex gw-min-w-0 gw-flex-1 gw-items-center gw-gap-1">
    <img class="gw-flow-block-thumbnail__tool gw-size-4" [src]="icon().src" [alt]="icon().alt" />
    <p
      class="gw-flow-block-thumbnail__name gw-max-w-full gw-overflow-hidden gw-overflow-ellipsis gw-whitespace-nowrap gw-text-sm gw-font-bold"
    >
      {{ name() }}
    </p>
  </div>
</div>
