import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { noop } from '../utils';

type CheckboxBorderColor = 'default-blue' | 'grey-4' | 'grey-5' | 'white';
type CheckboxFillColor = 'white' | 'default-blue' | 'no-fill';
type CheckboxTickColor = 'white' | 'default-blue' | 'no-fill';

/**
 * Global Web UI checkbox component.
 * @component
 * @name CheckboxComponent
 * @description
 * Checkbox component for use in forms, border color, fill color, and tick color in each state can be customized.
 *
 * @example
 * <gw-checkbox [(ngModel)]="isChecked" [checkedBorderColor]="'white'" [uncheckedBorderColor]="'grey-4'" [checkedFillColor]="'white'" [uncheckedFillColor]="'white'" [tickColor]="'default-blue'"></gw-checkbox>
 *
 * @example
 * <form [formGroup]="form">
 *   <gw-checkbox formControlName="checkboxControl" [label]="'Check me'"></gw-checkbox>
 * </form>
 *
 * @prerequisite
 * For template-driven forms, ensure `FormsModule` is imported.
 * For reactive forms, ensure `ReactiveFormsModule` is imported.
 */
@Component({
  selector: 'gw-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  /**
   * An optional label to display next to the checkbox.
   */
  @Input() label = '';
  /**
   * Whether the checkbox is checked.
   */
  @Input() checked = false;
  /**
   * Whether the checkbox is disabled.
   */
  @Input() disabled = false;
  /**
   * Additional classes to apply to the label element.
   */
  @Input() labelClass = '';
  /**
   * The border color of the checkbox when it is checked.
   */
  @Input() checkedBorderColor: CheckboxBorderColor = 'default-blue';
  /**
   * The border color of the checkbox when it is unchecked.
   */
  @Input() uncheckedBorderColor: CheckboxBorderColor = 'grey-4';
  /**
   * The fill color of the checkbox when it is checked.
   */
  @Input() checkedFillColor: CheckboxFillColor = 'default-blue';
  /**
   * The fill color of the checkbox when it is unchecked.
   */
  @Input() uncheckedFillColor: CheckboxFillColor = 'white';
  /**
   * The color of the checkmark/tick inside the checkbox.
   */
  @Input() tickColor: CheckboxTickColor = 'white';
  /**
   * Additional classes to apply to the host element.
   */
  @Input() class = '';
  /**
   * Event emitted when the checked state of the checkbox changes.
   */
  @Output() checkedChange = new EventEmitter<boolean>();

  @HostBinding('class')
  get hostClass() {
    const borderColor = this.checked ? this.checkedBorderColor : this.uncheckedBorderColor;
    const fillColor = this.checked ? this.checkedFillColor : this.uncheckedFillColor;
    const { tickColor } = this;

    return `gw-checkbox__border-${borderColor} gw-checkbox__fill-${fillColor} gw-checkbox__tick-${tickColor} ${this.class}`;
  }

  /* eslint-disable-next-line class-methods-use-this */
  private onChange: (value: boolean) => void = noop;
  /* eslint-disable-next-line class-methods-use-this */
  private onTouched = noop;

  writeValue(value: boolean): void {
    this.checked = value;
  }

  registerOnChange(fn: (_: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleChecked(event: Event) {
    event.preventDefault();

    if (!this.disabled) {
      this.checked = !this.checked;
      this.onChange(this.checked);
      this.onTouched();
      this.checkedChange.emit(this.checked);
    }
  }
}
