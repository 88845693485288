<div
  class="gw-connection-block-thumbnail gw-flex gw-h-[172px] gw-w-[440px] gw-items-center gw-gap-2 gw-rounded-10 gw-bg-white gw-p-4 gw-leading-0 gw-ring-2 gw-ring-inset gw-ring-grey-3"
>
  <div class="gw-flex gw-flex-col gw-gap-y-2">
    <div class="gw-flex gw-flex-col gw-gap-y-1">
      <div class="gw-flex gw-items-center gw-gap-x-2">
        <img class="gw-connection-block-thumbnail__tool gw-size-8" [src]="icon().src" [alt]="icon().alt" />
        <p class="gw-connection-block-thumbnail__name gw-text-base gw-font-bold">
          {{ name() }}
        </p>
      </div>
      <p
        class="gw-connection-block-thumbnail__description gw-line-clamp-2 gw-max-w-full gw-text-ellipsis gw-break-words gw-text-xs gw-font-normal gw-text-default-text"
      >
        {{ description() }}
      </p>
    </div>
    <button
      gw-button
      color="green"
      text="Connect"
      (click)="handleClick()"
      class="gw-connection-block-thumbnail__button gw-w-min gw-font-light"
    ></button>
  </div>
</div>
