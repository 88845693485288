import { Overlay, OverlayPositionBuilder, OverlayRef, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  inject,
} from '@angular/core';

import { TooltipIndicatorPosition, TooltipIndicatorPositionsMap } from './position.utils';
import { TooltipWindowComponent } from './tooltip-window/tooltip-window.component';

export { TooltipIndicatorPosition };

/**
 * This directive is used to attach the tooltip window to the DOM as an overlay element ref.
 * It is used by the host component to display the tooltip window when the mouse enters the
 * host component and hide the tooltip window when the mouse leaves the host component.
 *
 * @requires BrowserAnimationsModule from '@angular/platform-browser/animations' to provide animations.
 * import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
 *
 * @NgModule({
 *  imports: [BrowserAnimationsModule],
 *  providers: [provideAnimations()]
 * })
 * export class AppModule {}
 *
 * @requires AngularCDK OverlayCSS from '@angular/cdk/overlay' to provide overlay styles.
 * This comes by default when you have imported the `styles.scss` file
 * from the Global Web UI library in your main styles.scss file
 * or in the angular.json file.
 * In case not, make sure to include the overlay styles in your main
 * styles.scss file by adding `@import '@angular/cdk/overlay-prebuilt.css';`
 *
 * Usage example:
 * @example <caption>Using plain text</caption>
 * <button gwTooltip="Tooltip text" gwTooltipIndicatorPosition="RightTop" gwTooltipWindowClass="custom-tooltip-window" gwTooltipContainerClass="custom-tooltip-container">
 *  Host component
 * </button>
 *
 * @example <caption>Using a template reference</caption>
 * <button [gwTooltip]="tooltipContent" gwTooltipIndicatorPosition="BottomLeft" gwTooltipWindowClass="awesome-tooltip-window" gwTooltipContainerClass="awesome-tooltip-container">
 * <ng-template #tooltipContent>
 *  <div>
 *   <h3>Tooltip title</h3>
 *   <p>Tooltip content</p>
 *  </div>
 * </ng-template>
 */
@Directive({
  selector: '[gwTooltip]',
  standalone: true,
  providers: [Overlay, OverlayPositionBuilder, ScrollStrategyOptions],
})
export class TooltipDirective implements OnInit {
  private readonly elementRef = inject(ElementRef);
  private readonly overlay = inject(Overlay);
  private readonly overlayPositionBuilder = inject(OverlayPositionBuilder);
  private readonly scrollStrategyOptions = inject(ScrollStrategyOptions);
  private overlayRef!: OverlayRef;
  private _indicatorPosition: TooltipIndicatorPosition = TooltipIndicatorPosition.Left;
  public tooltipRef: ComponentRef<TooltipWindowComponent> | null = null;
  private hideTimeoutId: ReturnType<typeof setTimeout> | null = null;
  /**
   * The content to display in the tooltip window. Can be a string or a template reference.
   * It is accessed by the host component using the directive's input property `[gwTooltip]`.
   */
  @Input('gwTooltip') content: string | TemplateRef<HTMLElement> = '';
  /**
   * The class to apply to the tooltip window.
   * It is accessed by the host component using the directive's input property `[gwTooltipWindowClass]`.
   */
  @Input('gwTooltipWindowClass') windowClass = '';
  /**
   * The class to apply to the tooltip container.
   * It is accessed by the host component using the directive's input property `[gwTooltipContainerClass]`.
   */
  @Input('gwTooltipContainerClass') containerClass = '';
  /**
   * The amount of time in milliseconds to wait before hiding the tooltip.
   * It is accessed by the host component using the directive's input property `[gwTooltipHideDelay]`.
   */
  @Input('gwTooltipHideDelay') hideDelay = 100;

  /**
   * The position of the tooltip indicator.
   * It is accessed by the host component using the directive's input property `[gwTooltipIndicatorPosition]`.
   * The position of the tooltip window will be adjusted based on the indicator position and it will always point to one of the host/reference component center points (top, bottom, left, right).
   * @default TooltipIndicatorPosition.Left
   */
  @Input('gwTooltipIndicatorPosition') set indicatorPosition(value: TooltipIndicatorPosition) {
    const newPosition = value || TooltipIndicatorPosition.Left;
    this.overlayRef?.updatePositionStrategy(this.getPositionStrategy(newPosition));
    this._indicatorPosition = newPosition;
  }

  public get indicatorPosition(): TooltipIndicatorPosition {
    return this._indicatorPosition;
  }

  @HostListener('mouseenter')
  show() {
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
    }

    if (this.overlayRef.hasAttached()) {
      return;
    }
    const tooltipPortal = new ComponentPortal(TooltipWindowComponent);

    this.tooltipRef = this.overlayRef.attach(tooltipPortal);

    this.tooltipRef.instance.content = this.content;
    this.tooltipRef.instance.hostClass = this.windowClass;
    this.tooltipRef.instance.containerClass = this.containerClass;
    this.tooltipRef.instance.windowClose.subscribe(() => {
      this.overlayRef.detach();
    });
  }

  @HostListener('mouseleave')
  hide() {
    this.hideTimeoutId = setTimeout(() => {
      if (this.tooltipRef) {
        this.tooltipRef.instance.animationState = 'hidden';
      }
    }, this.hideDelay);
  }

  ngOnInit() {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.getPositionStrategy(this._indicatorPosition),
      scrollStrategy: this.scrollStrategyOptions.block(),
    });
  }

  public getPositionStrategy(position: TooltipIndicatorPosition) {
    return this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([TooltipIndicatorPositionsMap[position]]);
  }
}
