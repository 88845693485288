import { Component, computed, input } from '@angular/core';

import { TIconProps } from '../../thumbnail.types';

/**
 * A component for displaying a list of integration icons on a flow thumbnail.
 *
 * @example
 * <gw-flow-thumbnail-icons
 *  [integrationIcons]="[
 *   { src: 'assets/icons/logo-1.svg', alt: 'Logo 1' },
 *   { src: 'assets/icons/logo-2.svg', alt: 'Logo 2' }
 *  ]"
 * />
 */
@Component({
  selector: 'gw-flow-thumbnail-icons',
  templateUrl: 'flow-thumbnail-icons.component.html',
  standalone: true,
})
export class FlowThumbnailIconsComponent {
  public static MAX_INTEGRATION_ICONS = 7;
  /**
   * The icons to display.
   */
  public integrationIcons = input.required<TIconProps[]>();
  public isListTruncated = computed(
    () => this.integrationIcons().length > FlowThumbnailIconsComponent.MAX_INTEGRATION_ICONS,
  );
  /**
   * Truncates the list of integration icons if it exceeds the maximum number of icons to display.
   * If the list is truncated, the last icon is removed to make room for the ellipsis icon.
   */
  public truncatedIntegrationIcons = computed(() => {
    if (this.isListTruncated()) {
      return this.integrationIcons().slice(0, FlowThumbnailIconsComponent.MAX_INTEGRATION_ICONS - 1);
    }
    return this.integrationIcons();
  });
}
