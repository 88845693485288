<i class="cc-icon cc-icon-search gw-search__icon"></i>
<input
  #gwSearchInputElement
  type="text"
  [placeholder]="placeholder()"
  [ngClass]="['gw-search__input', textInputClass()]"
  [disabled]="disabled()"
  [value]="searchValue()"
  (input)="emitSearchValueChange($event)"
/>
<div class="gw-search__actions">
  @if (showClearButton()) {
    <button
      class="gw-search__actions--clear gw-flex"
      (click)="clearButtonClick($event)"
      (keyup.enter)="clearButtonClick($event)"
      aria-label="Clear search"
    >
      <i class="cc-icon cc-icon-close-circle-solid"></i>
    </button>
  }
</div>
